




























import { Component, Vue } from 'vue-property-decorator';
import AsyncButton from '@/components/shared/AsyncButton.vue';
import VocabularyListComponent from '@/components/VocabularyListComponent.vue';
import { Authenticator } from '@/services';
import { lazyInject } from '@/ioc/inversify.config';
import { TYPES } from '@/ioc/types';
import { ModuleProvider, Hanasu, NavRouter } from '@/services';
import { AxiosResponse } from 'axios';
import { UserInfo, EmptyPostPayload } from '@/types';
import { SHENGCI_MODULES, VUE_LIFECYCLE_EVENT } from '@/enums';

@Component({
  components: {
    AsyncButton, VocabularyListComponent
  }
})
export default class VocabularyView extends Vue
{  
  @lazyInject(TYPES.AUTHENTICATOR_INSTANCE)
  private authenticator!: Authenticator;

  @lazyInject(TYPES.MODULE_PROVIDER_INSTANCE)
  private module_provider!: ModuleProvider;

  @lazyInject(TYPES.NAVROUTER_INSTANCE)
  private nav_router!: NavRouter;

  @lazyInject(TYPES.HANASU_INSTANCE)
  private hanasu!: Hanasu;

  public readonly module_type: SHENGCI_MODULES = SHENGCI_MODULES.VOCABULARY;

  /*
  beforeCreate(): void {console.log('beforeCreate')}
  created(): void {console.log('created')}
  mounted(): void {console.log('mounted')}
  beforeMount(): void {console.log('beforeMount')}
  beforeUpdate(): void {console.log('beforeUpdate')}
  updated(): void {console.log('updated')}
  activated(): void {console.log('activated')}
  */

  mounted(): void {
    this.nav_router.pushModuleLifecycleEvent({module: this.module_type, event: VUE_LIFECYCLE_EVENT.MOUNTED});
  }

/*
  created(): void {
    console.log('vocabulary view is created');
    this.nav_router.pushModuleLifecycleEvent({module: SHENGCI_MODULES.VOCABULARY, event: VUE_LIFECYCLE_EVENT.CREATED});
  }

  activated(): void {
    console.log('vocabulary view is activated');
    this.nav_router.pushModuleLifecycleEvent({module: SHENGCI_MODULES.VOCABULARY, event: VUE_LIFECYCLE_EVENT.ACTIVATED});
  }
  */

  getUser(): void {
    this.hanasu.post<EmptyPostPayload, UserInfo>("/api/userinfo").subscribe({
      next: (response: AxiosResponse<UserInfo>) => {
        console.log('test success');
        console.log(response?.data);
      },
      error: (err) => {
        console.log('test error');
        console.log(err);
      }
    });
  }

  getVocabulary(): void {
    this.hanasu.post<EmptyPostPayload, string>('/api/vocabulary').subscribe({
      next: (response: AxiosResponse<string>) => {
        console.log('vocab success');
        console.log(response?.data);
      },
      error: (err) => {
        console.log('vocab error');
        console.log(err);
      }
    });
  }
}
